import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Heartafact from './assets/fondo.jpeg';
import color1 from './assets/color1.jpeg';
import color2 from './assets/color2.jpeg';
import logo from './assets/logo.jpeg';
import Whatsapp from './assets/whatsapp.png';
import { SpeedInsights } from "@vercel/speed-insights/react"

import { Carousel } from 'react-bootstrap';

const styles = {
  container: {
    fontFamily: "'Courier New', Courier, monospace",
    margin: 0,
    padding: 0,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1c1c1c',
    color: '#fff',
  },
  header: {
    backgroundColor: '#000',
    color: '#fff',
    padding: '20px',
    textAlign: 'center',
    borderBottom: '2px solid #b8860b',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    width: '100%',
    boxSizing: 'border-box',
  },
  serviceList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '10px',
    padding: 0,
    listStyle: 'none',
  },
  serviceItem: {
    backgroundColor: 'white',
    color: '#000',
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  infoSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  contactInfo: {
    flex: 1,
    minWidth: '200px',
    marginRight: '20px',
  },
  ctaButton: {
    padding: '15px 30px',
    fontSize: '18px',
    backgroundColor: '#b8860b',
    color: '#000',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontWeight: 'bold',
    textDecoration: 'none',
    display: 'inline-block',
    textAlign: 'center',
  },
  ctButton: {
    padding: '15px 30px',
    fontSize: '18px',
    backgroundColor: '#b8860b',
    color: '#000',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontWeight: 'bold',
    textDecoration: 'none',
    display: 'inline-block',
    position: 'relative',
  },
  mapContainer: {
    width: '100%',
    height: '300px',
    marginTop: '20px',
    border: '2px solid #b8860b',
  },
  footer: {
    backgroundColor: '#000',
    color: '#fff',
    padding: '10px',
    textAlign: 'center',
    borderTop: '2px solid #b8860b',
  },
  carouselImage: {
    height: '800px',
    objectFit: 'cover',
  },
  Icono: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '28px',
  },
  Icon: {
    width: '60px',
    height: '60px',

  }
};

const CarouselComponent = () => (
  <Carousel>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={Heartafact}
        alt="First slide"
        style={styles.carouselImage}
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={color1}
        alt="Second slide"
        style={styles.carouselImage}
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={color2}
        alt="Second slide"
        style={styles.carouselImage}
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={logo}
        alt="Third slide"
        style={styles.carouselImage}
      />
    </Carousel.Item>
  </Carousel>
);

const Map = () => {
  return (
    <div style={styles.mapContainer}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3561.8082019771427!2d-65.24931972523588!3d-26.782383087769695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94225d87f502f607%3A0xed9f66f27b815415!2sCabral&#39;s%20Barberia%20-%20Peluquer%C3%ADa%20Masculina!5e0!3m2!1ses!2sar!4v1719182461136!5m2!1ses!2sar"
        title="Ubicación de Cabral's Barbería en Google Maps"
        style={{ width: '100%', height: '100%', border: '0' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

const CabralsBarberia = () => {
  const services = ['Corte Clásico', 'Afeitado', 'Corte y Afeitado', 'Arreglo de Barba', 'Cortes Modernos', 'Mechas', 'Global', 'Permanente', 'Cejas'];

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <SpeedInsights />
        <h1>Cabral's Barbería</h1>
        <p>Estilo y precisión en cada corte</p>
        <a
          href="https://book.plandok.com/es/cabral-s-barbera-ticsvo"
          style={styles.ctButton}
        >
          Reserva tu cita ahora
        </a>
      </header>

      <CarouselComponent />

      <main style={styles.main}>
        <section>
          <h2>Bienvenido a Cabral's</h2>
          <p>En Cabral's Barbería, nos especializamos en brindar servicios de alta calidad para el cuidado del cabello y la barba de los caballeros modernos.</p>

          <h3 style={{ color: '#b8860b' }}>Nuestros Servicios</h3>
          <ul style={styles.serviceList}>
            {services.map((service) => (
              <li key={service} style={styles.serviceItem}>✔️{service}</li>
            ))}
          </ul>
        </section>

        <section style={styles.infoSection}>
          <div style={styles.contactInfo}>
            <p> 📌Lunes a Martes: 15:00 PM - 21:00 PM</p>
            <p> 📌Miercoles a Viernes: 10:00 AM - 13:00 PM | 15:00 PM - 21:00 PM</p>
            <p> 📌Sábado: 10:00 AM - 13:00 PM | 15:00 PM - 22:00 PM</p>
            <p> 📌Domingo: CERRADO</p>
            <p>📍 Lomas de Tafi - Tafi Viejo</p>
            <a
              style={{
                textDecoration: 'none',
                color: 'greenyellow',
                position: 'fixed',
                bottom: '20px',
                right: '20px',
              }}
              href="https://api.whatsapp.com/send/?phone=543815502176&text&type=phone_number&app_absent=0"
            >
              <div style={styles.Icono}>
                <img
                  src={Whatsapp}
                  alt='icono whatsapp'
                  style={styles.Icon}
                />
              </div>
            </a>
          </div>
        </section>
        <a
          href="https://book.plandok.com/es/cabral-s-barbera-ticsvo"
          style={styles.ctaButton}
        >
          Reserva tu cita ahora
        </a>

        <Map />
      </main>

      <footer style={styles.footer}>
        <p>&copy; 2024 Cabral's Barbería. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default CabralsBarberia;
